import createStore from "react-auth-kit/createStore";
import refreshApi from "./refresh";

const isProduction = process.env.NODE_ENV === "production";
const cookieDomain = isProduction ? ".presta.ge" : "localhost";

//import LoadingSpinner from "../InitialPage/Sidebar/LoadingSpinner";

const AuthStore = createStore({
  authName: "_prstAuth",
  authType: "cookie",
  cookieDomain,
  cookieSecure: isProduction,
  sameSite: isProduction ? "Lax" : "None",
  refresh: refreshApi,
});

export default AuthStore;
