import React, { useState } from "react";
import { Modal, TreeSelect } from "antd";
import { Link } from "react-router-dom";
import { PlusCircle } from "feather-icons-react/build/IconComponents";
import { useFormik } from "formik";
import useData from "../../hooks/useData";
import { useTranslation } from "react-i18next";

const AddCategory = ({ onSubmit, title, edit, initialValues }) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { data: categories } = useData("/products/categories", []);
  const {
    i18n: { language },
  } = useTranslation();

  const handleOk = async () => {
    setConfirmLoading(true);
    await formik.submitForm();
    setConfirmLoading(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      setConfirmLoading(true);
      await onSubmit(values);
      setConfirmLoading(false);
      setOpen(false);
    },
  });

  function transformToTree(data) {
    if (!data) {
      return undefined;
    }
    const idToNodeMap = new Map();
    const rootNodes = [];

    data.forEach((item) => {
      idToNodeMap.set(item._id, {
        ...item,
        title: item.name[language], // Use 'name' for display
        value: item._id,
        children: [],
      });
    });

    data.forEach((item) => {
      const node = idToNodeMap.get(item._id);
      if (item.parent) {
        const parentNode = idToNodeMap.get(item.parent);
        parentNode?.children.push(node);
      } else {
        rootNodes.push(node);
      }
    });

    return rootNodes;
  }
  const treeData = transformToTree(categories);

  console.log(formik.values.parent);

  return (
    <>
      {edit ? (
        <Link className="me-2 p-2" to="#" onClick={() => setOpen(true)}>
          <i data-feather="edit" className="feather-edit"></i>
        </Link>
      ) : (
        <a onClick={() => setOpen(true)} className="btn btn-added">
          <PlusCircle className="me-2" />
          Добавить
        </a>
      )}
      <Modal
        open={open}
        onHide={() => setOpen(false)}
        title={title}
        onOk={handleOk}
        onCancel={() => setOpen(false)}
        cancelText={"Отмена"}
        confirmLoading={confirmLoading}
        width={"60%"}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col col-lg-4">
              <div className="input-blocks add-product list">
                <label>დასახელება</label>
                <input
                  type={"text"}
                  name={"ka"}
                  value={formik.values.ka}
                  onChange={formik.handleChange}
                  className="form-control list"
                />
              </div>
            </div>

            <div className="col col-lg-4">
              <div className="input-blocks add-product list">
                <label>Название</label>
                <input
                  type={"text"}
                  name={"ru"}
                  value={formik.values.ru}
                  onChange={formik.handleChange}
                  className="form-control list"
                />
              </div>
            </div>

            <div className="col col-lg-4">
              <div className="input-blocks add-product list">
                <label>Name</label>
                <input
                  type={"text"}
                  name={"en"}
                  value={formik.values.en}
                  onChange={formik.handleChange}
                  className="form-control list"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col col-lg-12">
              <label>Значение</label>
              <TreeSelect
                size="large"
                name="parent"
                style={{ width: "100%" }}
                placeholder="Select an item"
                treeData={treeData}
                onChange={(value) => {
                  formik.setFieldValue("parent", value);
                }}
                value={formik.values.parent}
                filterTreeNode={(input, treeNode) =>
                  treeNode.title.toLowerCase().includes(input.toLowerCase())
                }
                showSearch
              />
              {/*<Select*/}
              {/*  name={"parent"}*/}
              {/*  defaultValue={formik.values.parent}*/}
              {/*  value={formik.values.parent}*/}
              {/*  placeholder={"Select"}*/}
              {/*  style={{*/}
              {/*    padding: 12,*/}
              {/*    width: "calc(100% + 24px)",*/}
              {/*    margin: "0 -12px",*/}
              {/*    height: 55,*/}
              {/*  }}*/}
              {/*  options={categories?.map((cat) => ({*/}
              {/*    label: cat.name[language],*/}
              {/*    value: cat._id,*/}
              {/*  }))}*/}
              {/*  onChange={(v) => formik.setFieldValue("parent", v)}*/}
              {/*/>*/}
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddCategory;
