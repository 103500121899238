import React, { useEffect, useState } from "react";
import { Alert, Button, ListGroup } from "react-bootstrap";
import { generateUUID } from "../../utils/generateUuid";
import Api from "../../Api";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import "dayjs/locale/ka";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import { useTranslation } from "react-i18next";
dayjs.extend(relativeTime);
const Track = () => {
  const [deviceId, setDeviceId] = useState("");
  const [result, setResult] = useState();
  const [userData, setUserData] = useState();
  const { i18n } = useTranslation();
  const { language } = i18n;
  dayjs.locale(language);
  const user = useAuthUser();

  useEffect(() => {
    let storedDeviceId = localStorage.getItem("deviceId");
    if (!storedDeviceId) {
      storedDeviceId = generateUUID();
      localStorage.setItem("deviceId", storedDeviceId);
    }
    setDeviceId(storedDeviceId);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Api.post("/getHours", { userId: user.uid });
      setUserData(response.data);
    };
    fetchData();
  }, [user.uid, result?.success]);

  const handleCheckIn = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const response = await Api.post("/track", {
          latitude,
          longitude,
          deviceId,
          userId: user.uid,
        });

        setResult(response.data);
      });
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <h2>Привет: {user.username}</h2>
          <ListGroup horizontal className="my-1 w-100">
            <ListGroup.Item>Ты последний раз на работе был</ListGroup.Item>
            <ListGroup.Item>
              {userData?.lastSeen
                ? dayjs(userData.lastSeen).fromNow()
                : "Никогда"}
            </ListGroup.Item>
          </ListGroup>
          <ListGroup horizontal className="my-1 w-100">
            <ListGroup.Item>Отработано за этот месяц</ListGroup.Item>
            <ListGroup.Item>
              {(userData?.workingHoursThisMonth || 0).toFixed(2)} часов
            </ListGroup.Item>
          </ListGroup>
          <ListGroup horizontal className="my-1">
            <ListGroup.Item>Отработано за эту неделю</ListGroup.Item>
            <ListGroup.Item>
              {(userData?.workingHoursThisWeek || 0).toFixed(2)} часов
            </ListGroup.Item>
          </ListGroup>
          {/*<ListGroup horizontal className="my-1">*/}
          {/*  <ListGroup.Item>Пропущено за этот месяц</ListGroup.Item>*/}
          {/*  <ListGroup.Item>*/}
          {/*    {(userData?.missingHours || 0).toFixed(2)} часов*/}
          {/*  </ListGroup.Item>*/}
          {/*</ListGroup>*/}
          {result && (
            <Alert variant={result?.success ? "success" : "danger"}>
              {result?.message}
            </Alert>
          )}
          <div className="d-grid gap-2">
            <Button
              onClick={handleCheckIn}
              type="button"
              className="btn btn-success btn-lg login-button"
            >
              {userData?.atTheOffice
                ? "Закончить рабочий день"
                : "Начать работу"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Track;
