import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { useFormik } from "formik";
import cn from "classnames";
import { Alert } from "react-bootstrap";
import Api from "../../../Api";
import useSignIn from "react-auth-kit/hooks/useSignIn";
const SigninThree = () => {
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  const signIn = useSignIn();
  const navigate = useNavigate();
  const [type, setType] = useState("password");
  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Required";
    }
    if (!values.password) {
      errors.password = "Required";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      rememberMe: false,
    },
    validate,
    onSubmit: async (values) => {
      const response = await Api.post("/login", values);
      const expiresIn = values.rememberMe ? 30 : 1;
      if (response.data.success === true) {
        if (
          signIn({
            auth: {
              token: response.data.accessToken,
              type: "Bearer",
            },
            refresh: response.data.refreshToken,
            expiresIn,
            userState: {
              uid: response.data.userData._id,
              ...response.data.userData,
            },
          })
        ) {
          navigate(from);
        } else {
          alert("Error occurred. Try Again");
        }
      } else {
        return <Alert>{response.data}</Alert>
      }
    },
  });

  const route = all_routes;
  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper login-new">
          <div className="container">
            <div className="login-content user-login">
              <div className="login-logo">
                <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                <Link to={route.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath
                    src="assets/img/logo-white.png"
                    alt="login"
                  />
                </Link>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="login-userset">
                  <div className="login-userheading">
                    <h3>Sign In</h3>
                  </div>
                  <div className="form-login">
                    <label className="form-label">Username</label>
                    <div className="form-addons">
                      <input
                        type="text"
                        className="form-control"
                        name="username"
                        onChange={formik.handleChange}
                      />
                      {formik.errors.username ? (
                        <Alert>{formik.errors.username}</Alert>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-login">
                    <label>Password</label>
                    <div className="pass-group">
                      <input
                        type={type}
                        className="pass-input"
                        name="password"
                        onChange={formik.handleChange}
                      />
                      <span
                        className={cn(
                          "fas",
                          " toggle-password",
                          type === "password" ? "fa-eye-slash" : "fa-eye",
                        )}
                        onClick={() =>
                          setType(type === "password" ? "text" : "password")
                        }
                      />
                      {formik.errors.password ? (
                        <Alert>{formik.errors.password}</Alert>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-login authentication-check">
                    <div className="row">
                      <div className="col-6">
                        <div className="custom-control custom-checkbox">
                          <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                            <input
                              type="checkbox"
                              name="rememberMe"
                              onChange={formik.handleChange}
                            />
                            <span className="checkmarks" />
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="col-6 text-end">
                        <Link
                          className="forgot-link"
                          to={route.forgotPasswordThree}
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="form-login">
                    <button type="submit" className="btn btn-login">
                      Sign In
                    </button>
                  </div>
                  <div className="signinform">
                    <h4>
                      New on our platform?
                      <Link to={route.registerThree} className="hover-a">
                        {" "}
                        Create an account
                      </Link>
                    </h4>
                  </div>
                </div>
              </form>
            </div>
            <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
              <p>
                Copyright © {new Date().getFullYear()} Presta. All rights
                reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninThree;
