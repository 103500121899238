import {
  Edit,
  PlusCircle,
  Trash2,
  Copy,
} from "feather-icons-react/build/IconComponents";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Brand from "../../core/modals/inventory/brand";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { all_routes } from "../../Router/all_routes";
import Table from "../../core/pagination/datatable";
import useData from "../../hooks/useData";
import { useTranslation } from "react-i18next";
import { Image, Input, Popconfirm, Tag, Button, Space } from "antd";
import Api from "../../Api";
import { toTree } from "../../utils/toTree";
import * as Icon from "react-feather";

const ProductList = () => {
  const { data: products, refetch, remove } = useData("/products/products", []);
  const { data: categories } = useData("/products/categories", []);
  const { data: dealers } = useData("/products/dealers", []);
  const { data: attributes } = useData("/products/attributes", []);
  const {
    i18n: { language },
  } = useTranslation();

  const getCategoryNameById = (categoryId) => {
    const category = categories?.find(
      (category) => category._id === categoryId,
    );
    return category ? category.name[language] : "N/A";
  };

  const getDealerNameById = (dealerId) => {
    const category = dealers?.find((category) => category._id === dealerId);
    return category ? category.name : "N/A";
  };

  const getAttributeNameById = (attributeId) => {
    const attribute = attributes?.find(
      (attribute) => attribute._id === attributeId,
    );
    return attribute ? attribute.name[language] : "N/A";
  };

  const getAttributeListByIds = (attributes) => {
    return attributes ? (
      <ul>
        {Object.entries(attributes).map(([key, value]) => (
          <li key={key}>
            {getAttributeNameById(key)}: <strong>{value}</strong>
          </li>
        ))}
      </ul>
    ) : (
      "-"
    );
  };

  const clone = async (id) => {
    await Api.post(`/products/clone`, {
      id,
    });
    await refetch();
  };

  const route = all_routes;

  const treeData = toTree(categories, language);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<Icon.Search />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon.Search
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <>
          {searchText} {text}
        </>
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      width: 350,
      ...getColumnSearchProps("name"),
      render: (text, record) => (
        <div
          style={{
            fontSize: 18,
            display: "flex",
            gap: 12,
            alignItems: "center",
          }}
        >
          {record?.file ? (
            <Image
              alt=""
              src={record.file}
              width={40}
              height={40}
              style={{
                background: "#FFF",
                borderRadius: 100,
              }}
            />
          ) : (
            ""
          )}
          {text}{" "}
          {record.code && (
            <>
              <br />{" "}
              <Tag>
                <Icon.Tag size={12} /> {record.code}
              </Tag>
            </>
          )}
        </div>
      ),
      filterSearch: true,
      onFilter: (searchValue, source) => {
        console.log(searchValue, source);
        return (
          source.name.includes(searchValue) || source.code.includes(searchValue)
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Аттрибуты",
      dataIndex: "attributes",
      filters: attributes.map((f) => ({
        ...f,
        text: f.name[language],
        children: f.values.map((v) => ({ text: v, value: v })),
      })),
      filterMode: "tree",
      render: (text) => getAttributeListByIds(text) || "N/A",
      sorter: (a, b) => a.code.localeCompare(b.code),
      onFilter: (searchValue, source) => {
        console.log(searchValue);
        return Object.values(source.attributes).includes(searchValue);
      },
    },
    {
      title: "Категория",
      dataIndex: "category",
      render: (text) => <span>{getCategoryNameById(text)}</span>,
      sorter: (a, b) => a.category.localeCompare(b.category),
      filters: treeData,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (searchValue, source) => source.category === searchValue,
    },
    {
      title: "Цена",
      dataIndex: "price",
      render: (text) => `${text.toFixed(2)} ₾`,
      sorter: (a, b) => a.price - b.price,
    },
    // {
    //   title: "Unit",
    //   dataIndex: "unit",
    //   render: (text) => (
    //     <span>
    //       {
    //         text /* Here, you might want to map unit ID to unit name if available */
    //       }
    //     </span>
    //   ),
    //   sorter: (a, b) => a.unit.localeCompare(b.unit),
    // },
    {
      title: "На складе",
      dataIndex: "totalStorage",
      render: (text, record) =>
        text > 0 ? (
          <div>
            {record.totalValue} {record.unitName[language]}{" "}
            <sup>({text} шт.)</sup>
          </div>
        ) : (
          "N/A"
        ),
      sorter: (a, b) => a.qty - b.qty,
    },
    {
      title: "Поставщик",
      dataIndex: "dealer",
      render: (text) => (
        <span className="userimgname">{getDealerNameById(text)}</span>
      ),
      sorter: (a, b) => a.dealer.localeCompare(b.dealer),
      filters: dealers?.map((d) => ({ text: d.name, value: d._id })),
      onFilter: (searchValue, source) => source.dealer === searchValue,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 70,
      render: (_, record) => (
        <div className="edit-delete-action">
          <Link className="me-2 p-2" to={`/add-product/${record._id}`}>
            <Edit className="feather-edit" />
          </Link>
          <Popconfirm
            title="Подтверждение клонирования"
            description={`Вы уверены, что хотите клонировать продукт ${record.name}?`}
            onConfirm={() => clone(record._id)}
          >
            <a className="me-2 p-2">
              <Copy className="feather-edit" />
            </a>
          </Popconfirm>
          <Link
            className="confirm-text p-2"
            to="#"
            onClick={() => showConfirmationAlert(record._id)}
          >
            <Trash2 className="feather-trash-2" />
          </Link>
        </div>
      ),
      sorter: (a, b) => a._id.localeCompare(b._id),
    },
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (id) => {
    MySwal.fire({
      title: "Вы точно хотите удалить продукт?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Да, Удалить!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Отмена",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await remove(id);

        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Продукция</h4>
              <h6>Список продуктов</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to={route.addproduct} className="btn btn-added">
              <PlusCircle className="me-2 iconsize" />
              Добавить продукт
            </Link>
          </div>
        </div>
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={products}
            size="medium"
            bordered
          />
        </div>
        {/* /product list */}
        <Brand />
      </div>
    </div>
  );
};

export default ProductList;
