import React from "react";
import { Link } from "react-router-dom";
import Table from "../../core/pagination/datatable";
import Swal from "sweetalert2";
import {
  Edit,
  PlusCircle,
  Trash2,
  ShoppingCart,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import useData from "../../hooks/useData";
import NumberFormat from "../../utils/NumberFormat";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { Dropdown, Modal, DatePicker, Space, message } from "antd";
import { MoreHorizontal } from "react-feather";
import dayjs from "dayjs";
import Api from "../../Api";

const ProductCalcList = () => {
  const { _id } = useAuthUser();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState();
  const {
    data: dataSource,
    remove,
    isLoading: isLoadingCalc,
  } = useData("/products/calc", []);
  const { data: products, isLoading: isLoadingProducts } = useData(
    "/products/products",
    [],
  );

  const columns = [
    {
      title: "",
      width: 10,
    },
    {
      title: "Название",
      dataIndex: "name",
      sorter: (a, b) => a?.name - b?.name,
    },
    {
      title: "Кол-во продуктов",
      dataIndex: "selectedProducts",
      render: (text) => <span>{text.length}</span>,
    },
    {
      title: "Себестоимость",
      dataIndex: "selectedProducts",
      render: (text) => {
        const total = text.reduce((acc, v) => {
          const product = products?.find((p) => p._id === v.value);
          const total =
            v.unit === "66428b8c5a60f8cde1e5cb1b"
              ? product?.price * v?.quantity
              : (product?.price * v?.quantity) / (product?.value || 1);
          return acc + total;
        }, 0);
        return `${NumberFormat(total)} ₾`;
      },
    },
    {
      title: "Действа",
      dataIndex: "actions",
      key: "actions",
      width: 130,
      render: (_, record) => (
        <td className="action-table-data">
          <Dropdown
            menu={{
              items: [
                {
                  key: 1,
                  label: (
                    <Link
                      className="p-2 me-2"
                      to={`/product-calc-add/${record._id}`}
                    >
                      <Edit className="feather-edit" size={14} /> Редактировать
                    </Link>
                  ),
                },
                {
                  key: 2,
                  label: (
                    <Link
                      className="p-2 me-2"
                      to={`#`}
                      onClick={() => {
                        setSelectedProduct(record);
                        setModalOpen(true);
                      }}
                    >
                      <ShoppingCart className="feather-edit" size={14} />{" "}
                      Перенести в закупки
                    </Link>
                  ),
                },
                {
                  key: 3,
                  danger: true,
                  label: (
                    <Link
                      className="confirm-text p-2  me-2"
                      to="#"
                      onClick={() => showConfirmationAlert(record._id)}
                    >
                      <Trash2 className="feather-edit" size={14} /> Удалить
                    </Link>
                  ),
                },
              ],
            }}
          >
            <MoreHorizontal />
          </Dropdown>
        </td>
      ),
    },
  ];
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await remove(id);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const name = data.get("name");
    const date = data.get("date");
    const comment = data.get("comment");
    const response = await Api.post("/purchase/fromCalc", {
      id: selectedProduct?._id,
      name,
      date,
      comment,
      author: _id,
    });
    if (response.status === 200) {
      setModalOpen(false);
      message.success(`Заявка на закупки ${name} успешно создана`);
    }
  };

  return (
    <div className="page-wrapper">
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <form onSubmit={handleSubmit}>
          <div className="modal-header">
            <h5>Перенести в закупки</h5>
          </div>
          <div
            className="modal-body"
            style={{ display: "flex", flexDirection: "column", gap: 12 }}
          >
            <div className="form-group">
              <label>
                Название <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedProduct?.name}
                required
                name={"name"}
              />
            </div>
            <div className="form-group">
              <label>
                Когда надо купить? <span className="text-danger">*</span>
              </label>
              <div className={"form-control"}>
                <DatePicker
                  name={"date"}
                  variant={"borderless"}
                  style={{ width: "100%" }}
                  disabledDate={disabledDate}
                  required
                />
              </div>
            </div>
            <div className={"form-group mb-3"}>
              <label>Коментарий:</label>
              <textarea className="form-control" name={"comment"} />
            </div>
          </div>
          <div className="modal-footer">
            <Space>
              <button className="btn btn-primary" type={"submit"}>
                Сохранить
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setModalOpen(false)}
              >
                Отмена
              </button>
            </Space>
          </div>
        </form>
      </Modal>
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Калькулация</h4>
            </div>
          </div>
          <div className="page-btn">
            <Link to={"/product-calc-add/"} className="btn btn-added">
              <PlusCircle className="me-2 iconsize" />
              Добавить
            </Link>
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="table-responsive">
            <Table
              columns={columns}
              dataSource={dataSource}
              loading={isLoadingCalc || isLoadingProducts}
            />
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default ProductCalcList;
