import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { all_routes } from "../../Router/all_routes";
import { notification, Radio, TreeSelect, Select } from "antd";
import { Alert, Table } from "react-bootstrap";
import {
  ArrowLeft,
  ChevronDown,
  Info,
} from "feather-icons-react/build/IconComponents";

import useData from "../../hooks/useData";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { productSchema } from "./product.schema";
import Api from "../../Api";
import UploadComponent from "../../Components/Upload";
import AddCategory from "../../Components/AddCategory";
import { Search } from "react-feather";

const AddProduct = () => {
  const route = all_routes;
  const [adding, setAdding] = useState(false);
  const { id } = useParams();
  const { data: units } = useData("/products/units", []);
  const { data: brands } = useData("/products/brands", []);
  const { data: dealers } = useData("/products/dealers", []);
  const { data: categories, update } = useData("/products/categories", []);
  const { data: attributes } = useData("/products/attributes", []);
  const [loading, setLoading] = useState(false);
  const [imageSearchResult, setImageSearchResult] = useState([]);
  const [selectedImage, setSelectedImage] = useState(-1);
  const {
    i18n: { language },
  } = useTranslation();

  const [api, contextHolder] = notification.useNotification({
    duration: 2,
  });

  const initialValues = {
    name: "",
    description: "",
    code: "",
    category: "",
    brand: "",
    dealer: "",
    unit: "",
    price: "",
    attributes: [],
    file: "",
    when: 0,
    value: undefined,
  };

  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      setAdding(true);
      const response = await Api.post("/products/add", values);
      if (response.status === 200) {
        api.open({
          type: "success",
          message: "Успех",
          description: `Продукт ${formik.values.name} добавлен`,
          showProgress: true,
          pauseOnHover: true,
        });
        if (!id) {
          formik.resetForm();
          setSelectedAttributes([]);
        }
      } else {
        api.open({
          type: "error",
          message: "Ошибка",
          description: `Продукт ${formik.values.name} не добавлен`,
          showProgress: true,
          pauseOnHover: true,
        });
      }
      setAdding(false);
    },
    validationSchema: productSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleEdit = async ({ id, data }) =>
    update({
      id,
      ...{
        name: {
          ka: data.ka,
          en: data.en,
          ru: data.ru,
        },
        parent: data.parent,
      },
    });

  useEffect(() => {
    (async () => {
      if (id) {
        const response = await Api.get(`/products/details/${id}`);
        await formik.setValues(response.data);
        response.data?.attributes &&
          setSelectedAttributes(Object.keys(response.data?.attributes));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function transformToTree(data) {
    if (!data) {
      return undefined;
    }
    const idToNodeMap = new Map();
    const rootNodes = [];

    data.forEach((item) => {
      idToNodeMap.set(item._id, {
        ...item,
        title: item.name[language], // Use 'name' for display
        value: item._id,
        children: [],
      });
    });

    data.forEach((item) => {
      const node = idToNodeMap.get(item._id);
      if (item.parent) {
        const parentNode = idToNodeMap.get(item.parent);
        parentNode?.children.push(node);
      } else {
        rootNodes.push(node);
      }
    });

    return rootNodes;
  }
  const treeData = transformToTree(categories);

  const searchImages = async (query) => {
    setLoading(true);
    const apiKey = "AIzaSyB3fwUCkxIgJTPiJzknS_y7s373_9NZZQA";
    const searchEngineId = "e12070949f85942ed";

    try {
      const response = await fetch(
        `https://www.googleapis.com/customsearch/v1?q=${query}&searchType=image&key=${apiKey}&cx=${searchEngineId}`,
      );
      const data = await response.json();
      setImageSearchResult(data.items);
    } catch (error) {
      console.error("Ошибка при поиске изображений:", error);
    }

    setLoading(false);
  };

  const getUnitName = (unitId) => {
    const unit = units?.find((unit) => unit._id === unitId);
    return unit ? unit.name[language] : "N/A";
  };

  return (
    <div className="page-wrapper">
      {contextHolder}
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Добавить продукт</h4>
              <h6>Создать новый продукт</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <div className="page-btn">
                <Link to={route.productlist} className="btn btn-secondary">
                  <ArrowLeft className="me-2" />К списку продуктов
                </Link>
              </div>
            </li>
          </ul>
        </div>
        {/* /add */}
        <form onSubmit={formik.handleSubmit}>
          <div className="card">
            <div className="card-body add-product pb-0">
              <div
                className="accordion-card-one accordion"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      <div className="addproduct-icon">
                        <h5>
                          <Info className="add-info" />

                          <span>Описание продукта</span>
                        </h5>
                        <Link to="#">
                          <ChevronDown className="chevron-down-add" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-lg-6 col-sm-12 col-8">
                          <div className="mb-3 add-product">
                            <label className="form-label">Название</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              onChange={formik.handleChange}
                              value={formik.values.name}
                            />
                            {formik.errors.name && (
                              <Alert>{formik.errors.name}</Alert>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>
                              Сообщить когда менее{" "}
                              <sup>{getUnitName(formik.values.unit)}</sup>
                            </label>
                            <input
                              name="when"
                              type="number"
                              className="form-control list"
                              onChange={formik.handleChange}
                              value={formik.values.when}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>
                              Цена <sup>₾.</sup>
                            </label>
                            <input
                              name="price"
                              type="number"
                              className="form-control list"
                              onChange={formik.handleChange}
                              value={formik.values.price}
                            />{" "}
                            {formik.errors.price && (
                              <Alert>{formik.errors.price}</Alert>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>Код продукта</label>
                            <input
                              name="code"
                              type="text"
                              className="form-control list"
                              placeholder="Код продукта"
                              onChange={formik.handleChange}
                              value={formik.values.code}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <div className="add-newplus">
                              <label className="form-label">Категория</label>
                              <AddCategory
                                onSubmit={(v) => handleEdit({ data: v })}
                                title={"Добавить категорию"}
                                initialValues={{
                                  ka: "",
                                  ru: "",
                                  en: "",
                                  parent: [],
                                }}
                              />
                            </div>
                            {treeData && (
                              <TreeSelect
                                size="large"
                                name="category"
                                style={{ width: "100%" }}
                                placeholder="Select an item"
                                treeData={treeData}
                                onChange={(value) => {
                                  formik.setFieldValue("category", value);
                                }}
                                value={formik.values.category}
                                filterTreeNode={(input, treeNode) =>
                                  treeNode.title
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                showSearch
                              />
                            )}
                            {formik.errors.category && (
                              <Alert>{formik.errors.category}</Alert>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <div className="add-newplus">
                              <label className="form-label">Поставщик</label>
                            </div>
                            {brands && (
                              <Select
                                showSearch
                                allowClear
                                filterOption={(input, treeNode) =>
                                  treeNode.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                size="large"
                                style={{ width: "100%" }}
                                name="dealer"
                                className="select"
                                options={dealers?.map((dealer) => ({
                                  value: dealer._id,
                                  label: dealer.name,
                                }))}
                                onChange={(option) =>
                                  formik.setFieldValue("dealer", option || "")
                                }
                                defaultValue={[formik.values?.dealer]}
                                value={formik.values.dealer}
                                placeholder="Выберите"
                              />
                            )}
                            {formik.errors.dealer && (
                              <Alert>{formik.errors.dealer}</Alert>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <div className="add-newplus">
                              <label className="form-label">Брэнд</label>
                            </div>
                            {brands && (
                              <Select
                                style={{ width: "100%" }}
                                name="brand"
                                showSearch
                                allowClear
                                size="large"
                                filterOption={(input, treeNode) =>
                                  treeNode.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                className="select"
                                options={brands?.map((brand) => ({
                                  value: brand._id,
                                  label: brand.name,
                                }))}
                                defaultValue={[formik.values?.brand]}
                                onChange={(option) =>
                                  formik.setFieldValue("brand", option || "")
                                }
                                value={formik.values.brand}
                                placeholder="Опционально"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <div className="add-newplus">
                              <label className="form-label">Ед. изм.</label>
                            </div>
                            {units && (
                              <Select
                                style={{ width: "100%" }}
                                name="unit"
                                className="select"
                                showSearch
                                allowClear
                                size="large"
                                filterOption={(input, treeNode) =>
                                  treeNode.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={units?.map((unit) => ({
                                  value: unit._id,
                                  label: unit.name[language],
                                }))}
                                onChange={(option) =>
                                  formik.setFieldValue("unit", option)
                                }
                                value={formik.values?.unit}
                                placeholder="Выберите"
                              />
                            )}
                            {formik.errors.unit && (
                              <Alert>{formik.errors.unit}</Alert>
                            )}
                          </div>
                        </div>
                        {!!formik.values.unit &&
                          formik.values.unit !== "66428b8c5a60f8cde1e5cb1b" && (
                            <div className="col-lg-2 col-sm-6 col-12">
                              <div className="mb-3 add-product">
                                <div className="input-blocks add-product list">
                                  <label>
                                    Сколько {getUnitName(formik.values.unit)}-ов
                                    в 1 шт.
                                  </label>
                                  <input
                                    name="value"
                                    type="number"
                                    step="0.01"
                                    className="form-control list"
                                    placeholder="Например: 5.88"
                                    onChange={formik.handleChange}
                                    value={formik.values.value}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks summer-description-box transfer mb-3">
                          <label>Описание</label>
                          <textarea
                            name="description"
                            className="form-control h-100"
                            rows={5}
                            defaultValue={""}
                            onChange={formik.handleChange}
                            value={formik.values.description}
                          />
                          <p className="mt-1">Макс 60 симв</p>
                        </div>
                      </div>
                      <div>
                        <h6>Добавить аттрибут</h6>
                        <Select
                          mode="multiple"
                          name="attributes"
                          style={{ width: "100%" }}
                          size={"large"}
                          options={attributes?.map((attribute) => ({
                            value: attribute._id,
                            label: attribute?.name[language],
                          }))}
                          onChange={(option) => {
                            setSelectedAttributes(option);
                          }}
                          value={selectedAttributes}
                          placeholder="Выберите аттрибуты"
                          className="mt-3"
                        />
                      </div>
                      {selectedAttributes.length ? (
                        <Table striped bordered hover>
                          {selectedAttributes.map((attr) => {
                            const attribute = attributes.find(
                              (a) => attr === a._id,
                            );
                            return (
                              <tr key={`attribute.${attribute?._id}`}>
                                <td>{attribute?.name[language]}</td>
                                <td>
                                  <div>
                                    {attributes
                                      ?.filter(
                                        (attr) => attr._id === attribute?._id,
                                      )
                                      .map((attr) => (
                                        <Radio.Group
                                          className={"checkbox-container"}
                                          key={attribute?._id}
                                          name={`attribute.${attribute?._id}`}
                                          onChange={(e) => {
                                            const selectedValue =
                                              e.target.value;
                                            formik.setFieldValue("attributes", {
                                              ...formik.values.attributes,
                                              [attribute?._id]: selectedValue,
                                            });
                                          }}
                                          value={
                                            formik.values.attributes[
                                              attribute?._id
                                            ] || ""
                                          }
                                        >
                                          {attr.values.map((value) => (
                                            <Radio
                                              value={value}
                                              checked={false}
                                              size={"large"}
                                              key={`value.${value}`}
                                              className={"checkbox"}
                                            >
                                              {value}
                                            </Radio>
                                          ))}
                                        </Radio.Group>
                                      ))}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </Table>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="input-blocks add-product">
                    <h4>Загрузить изображение</h4>
                    {formik && formik.values && formik.values.name && (
                      <button
                        onClick={() =>
                          searchImages(
                            formik?.values ? formik?.values?.name : "",
                          )
                        }
                        disabled={loading}
                        type="button"
                        className="btn btn-secondary mt-2"
                      >
                        <Search size={15} /> Поиск фото в интернете
                      </button>
                    )}
                    <div>
                      {imageSearchResult.length ? (
                        <div className="row">
                          {imageSearchResult.map((image, index) => (
                            <div
                              key={index}
                              className="col-lg-2 col-sm-6 col-12"
                            >
                              <div
                                className={`card ${
                                  selectedImage === index ? "selected" : ""
                                }`}
                              >
                                <img
                                  src={image.link}
                                  alt={image.title}
                                  className="card-img-top"
                                  height={200}
                                  width={200}
                                  style={{
                                    objectFit: "contain",
                                    objectPosition: "center",
                                    height: "200px", // or '100%'
                                    maxWidth: "100%",
                                  }}
                                />
                                <div className="card-body">
                                  <button
                                    type={"button"}
                                    onClick={() => {
                                      setSelectedImage(index);
                                      formik.setFieldValue("file", image.link);
                                    }}
                                    className={`btn ${selectedImage === index ? "btn-success" : "btn-primary"} w-100`}
                                    disabled={selectedImage === index}
                                  >
                                    {selectedImage === index
                                      ? "Выбрано"
                                      : "Выбрать"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    <UploadComponent
                      onUpload={(imageUrl) =>
                        formik.setFieldValue("file", imageUrl)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-4">
                <div className="btn-addproduct mb-4">
                  <button type="button" className="btn btn-cancel me-2">
                    Отмена
                  </button>
                  <button
                    type={"submit"}
                    className="btn btn-submit"
                    disabled={adding}
                  >
                    Создать
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProduct;
