export const dashboarrecentproductddata = [
	{
		"id": "1",
		"img":"assets/img/products/stock-img-01.png",
		"products": "Lenevo 3rd Generation",
		"price": "$12500"
	},
	{
		"id": "2",
		"img":"assets/img/products/stock-img-06.png",
		"products": "Bold V3.2",
		"price": "$1600"
	},
	{
		"id": "3",
		"img": "assets/img/products/stock-img-02.png",
		"products": "Nike Jordan",
		"price": "$2000"
	},
	{
		"id": "4",
		"img": "assets/img/products/stock-img-03.png",
		"products": "Apple Series 5 Watch",
		"price": "$800"
	}
]