import React from "react";
import { Provider } from "react-redux";
import store from "./core/redux/store";
import { BrowserRouter } from "react-router-dom";
import { base_path } from "./environment";
import AllRoutes from "./Router/router";
import { useTranslation } from "react-i18next";

function App() {
  const all = useTranslation();
  console.log("translate", all.t("nav.Dashboard"));
  return (
    <Provider store={{ ...store }}>
      <BrowserRouter basename={base_path}>
        <AllRoutes />
      </BrowserRouter>
    </Provider>
  );
}
export default App;
