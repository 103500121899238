import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en.json";
import ka from "./locale/ka.json";
import ru from "./locale/ru.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    }, // English
    ka: {
      translation: ka,
    }, // Georgian
    ru: {
      translation: ru,
    }, // Russian
  },
  debug: true,
  lng: "ru",
  interpolation: {
    escapeValue: true,
  },
});

export default i18n;
