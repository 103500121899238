export function toTree(data, language) {
  if (!data) {
    return undefined;
  }
  const idToNodeMap = new Map();
  const rootNodes = [];

  data.forEach((item) => {
    idToNodeMap.set(item._id, {
      ...item,
      text: item.name[language], // Use 'name' for display
      title: item.name[language], // Use 'name' for display
      value: item._id,
      children: [],
    });
  });

  data.forEach((item) => {
    const node = idToNodeMap.get(item._id);
    if (item.parent) {
      const parentNode = idToNodeMap.get(item.parent);
      parentNode?.children.push(node);
    } else {
      rootNodes.push(node);
    }
  });

  const removeEmptyChildren = (nodes) => {
    return nodes.map((node) => {
      if (node.children.length > 0) {
        node.children = removeEmptyChildren(node.children);
      } else {
        delete node.children; // Remove empty 'children' array
      }
      return node;
    });
  };

  return removeEmptyChildren(rootNodes);
}

export const filterTreeData = (nodes, searchValue) => {
  return nodes
    .map((node) => {
      // Check if the current node's text or any of its children match the search value
      if (
        node.text.toLowerCase().includes(searchValue.toLowerCase()) ||
        (node.children && filterTreeData(node.children, searchValue).length > 0)
      ) {
        return {
          ...node,
          children: filterTreeData(node.children || [], searchValue),
        };
      }
      return null;
    })
    .filter((node) => node !== null); // Remove null entries
};
