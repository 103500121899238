import createRefresh from "react-auth-kit/createRefresh";
import Api from "../../Api";
import { redirect } from "react-router-dom";

const refreshApi = createRefresh({
  interval: 60,
  refreshApiCallback: async (param) => {
    try {
      const response = await Api.post("/refresh", param, {
        headers: { Authorization: `Bearer ${param.authToken}` },
      });
      return {
        isSuccess: true,
        newAuthToken: response.data.accessToken,
        newAuthTokenExpireIn: 60,
        newRefreshTokenExpiresIn: 60 * 60 * 7,
      };
    } catch (error) {
      redirect("/login");
      return {
        isSuccess: false,
      };
    }
  },
});

export default refreshApi;
