import Api from "../Api";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

function useData(url, initialState) {
  const [, , s] = url.split("/");
  const table = s.split("?")[0];
  const fetcher = (url) => Api.get(url).then((res) => res.data);

  // For fetching data
  const { data, error, isLoading, mutate } = useSWR(url, fetcher, {
    fallbackData: initialState,
  });

  // For updating data
  const { trigger: refetch, isMutating } = useSWRMutation(url, fetcher);

  const update = async (data, url) => {
    const response = await Api.post(url || `/addEdit?table=${table}`, data);
    await refetch();
    return response;
  };

  const remove = async (id) => {
    const response = await Api.post(`/removeData?table=${table}`, {
      _id: id,
    });
    await refetch();
    return response;
  };

  return {
    data,
    error,
    isLoading,
    mutate, // Function to revalidate data
    refetch,
    update,
    remove,
    isMutating, // State for the mutation process
  };
}

export default useData;
