export const leavedata = [
	{
		"id":1,
		"name": "Newyear",
		"date": "01 Jan 2024",
		"duration": "1 Day",
		"createdon": "04 Aug 2023",
		"status": "Active",
		
	},
	{
		"id":2,
		"name": "Pongal",
		"date": "14 Jan 2024",
		"duration": "1 Day",
		"createdon": "31 Jan 2022",
		"status": "Active",
		
	},
	{
		"id":3,
		"name": "Republic Day",
		"date": "25 Jan 2024",
		"duration": "1 Day",
		"createdon": "21 July 2023",
		"status": "Active",
		
	},
	{
		"id":4,
		"name": "Worker’s Day",
		"date": "01 May 2024",
		"duration": "1 Day",
		"createdon": "15 May 2023",
		"status": "Active",
		
	},
	{
		"id":5,
		"name": "Deepavali",
		"date": "14 Oct 2024",
		"duration": "1 Day",
		"createdon": "04 Aug 2023",
		"status": "Active",
		
	}
]