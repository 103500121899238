export const barcodedata = [
	{
		"product": "Nike Jordan",
		"sku": "PT002",
		"code": "HG3FK",
		"qty": "+",
	},
	{
		"product": "Apple Series 5 Watch",
		"sku": "PT003",
		"code": "TEUIU7",
		"qty": "+",
	}
]