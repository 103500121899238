export const expiredproduct = [
	{
		"id":1,
		"img":"assets/img/products/stock-img-01.png",
		"product": "Lenovo 3rd Generation",
		"sku": "PT001",
		"manufactureddate": "19 Nov 2022",
		"expireddate": "02 Jan 2023",
		
	},
	{
		"id":2,
		"img":"assets/img/products/stock-img-02.png",

		"product": "Nike Jordan",
		"sku": "PT002",
		"manufactureddate": "24 Nov 2022",
		"expireddate": "23 Jan 2023",
		
	},
	{
		"id":3,
		"img":"assets/img/products/stock-img-03.png",

		"product": "Apple Series 5 Watch",
		"sku": "PT003",
		"manufactureddate": "11 Dec 2022",
		"expireddate": "18 Feb 2023",
		
	},
	{
		"id":4,
		"img":"assets/img/products/stock-img-04.png",

		"product": "Amazon Echo Dot",
		"sku": "PT004",
		"manufactureddate": "27 Dec 2022",
		"expireddate": "24 Feb 2023",
		
	},
	{
		"id":5,
		"img":"assets/img/products/stock-img-05.png",

		"product": "Lobar Handy",
		"sku": "PT005",
		"manufactureddate": "08 Jan 2023",
		"expireddate": "16 Mar 2023",
		
	},
	{
		"id":6,
		"img":"assets/img/products/stock-img-06.png",

		"product": "Red Premium Handy",
		"sku": "PT006",
		"manufactureddate": "17 Jan 2023",
		"expireddate": "29 Mar 2023",
		
	},
	{
		"id":7,
		"img":"assets/img/products/expire-product-02.png",

		"product": "Red Premium Handy",
		"sku": "PT007",
		"manufactureddate": "22 Feb 2023",
		"expireddate": "04 Apr 2023",
		
	},
	{
		"id":8,
		"img":"assets/img/products/expire-product-01.png",

		"product": "Black Slim 200",
		"sku": "PT008",
		"manufactureddate": "18 Mar 2023",
		"expireddate": "13 May 2023",
		
	},
	{
		"id":9,
		"img":"assets/img/products/expire-product-03.png",

		"product": "Woodcraft Sandal",
		"sku": "PT009",
		"manufactureddate": "29 Mar 2023",
		"expireddate": "27 May 2023",
		
	}
]