import React, { useState } from "react";
import { Modal, Select } from "antd";
import { Link } from "react-router-dom";
import { PlusCircle } from "feather-icons-react/build/IconComponents";
import { useFormik } from "formik";

const AddModal = ({ onSubmit, title, edit, initialValues }) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    await formik.submitForm();
    setConfirmLoading(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      setConfirmLoading(true);
      await onSubmit(values);
      setConfirmLoading(false);
      setOpen(false);
    },
  });

  return (
    <>
      {edit ? (
        <Link className="me-2 p-2" to="#" onClick={() => setOpen(true)}>
          <i data-feather="edit" className="feather-edit"></i>
        </Link>
      ) : (
        <a onClick={() => setOpen(true)} className="btn btn-added">
          <PlusCircle className="me-2" />
          Добавить
        </a>
      )}
      <Modal
        open={open}
        onHide={() => setOpen(false)}
        title={title}
        onOk={handleOk}
        onCancel={() => setOpen(false)}
        cancelText={"Отмена"}
        confirmLoading={confirmLoading}
        width={"60%"}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col col-lg-4">
              <div className="input-blocks add-product list">
                <label>დასახელება</label>
                <input
                  type={"text"}
                  name={"ka"}
                  value={formik.values.ka}
                  onChange={formik.handleChange}
                  className="form-control list"
                />
              </div>
            </div>

            <div className="col col-lg-4">
              <div className="input-blocks add-product list">
                <label>Название</label>
                <input
                  type={"text"}
                  name={"ru"}
                  value={formik.values.ru}
                  onChange={formik.handleChange}
                  className="form-control list"
                />
              </div>
            </div>

            <div className="col col-lg-4">
              <div className="input-blocks add-product list">
                <label>Name</label>
                <input
                  type={"text"}
                  name={"en"}
                  value={formik.values.en}
                  onChange={formik.handleChange}
                  className="form-control list"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col col-lg-12">
              <label>Значение</label>
              <Select
                mode={"tags"}
                name={"values"}
                defaultValue={formik.values.values}
                style={{
                  padding: 12,
                  width: "calc(100% + 24px)",
                  margin: "0 -12px",
                }}
                onChange={(v) => formik.setFieldValue("values", v)}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddModal;
