import React, { useState } from "react";
import { message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Api from "../../Api";

// import Api from "../../Api";

const UploadComponent = ({ onUpload, initialFile }) => {
  const [fileList] = useState(
    initialFile
      ? [
          {
            uid: "-1",
            name: "image",
            status: "done",
            url: initialFile,
          },
        ]
      : undefined,
  );
  const props = {
    name: "image",
    action: `${process.env.REACT_APP_API_URL}upload`,
    listType: "picture-card",
    fileList: fileList,
    maxFileCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        onUpload(info.file.response.imageUrl);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onPreview: async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    },
    onRemove: async (file) => {
      const response = await Api.delete("/upload", {
        data: {
          key: file.response.imageUrl?.replace(
            "https://prestastorage90158-staging.s3.eu-central-1.amazonaws.com/",
            "",
          ),
        },
      });
      if (response.status === 200) {
        message.success("Image deleted successfully");
        onUpload(null);
      } else {
        message.error("Error occurred. Try again");
      }
    },
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <UploadOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Выберите файл
      </div>
    </button>
  );
  return (
    <div className="mt-3">
      <Upload {...props} maxCount={1}>
        {!fileList || fileList?.length < 1 ? uploadButton : null}
      </Upload>
    </div>
  );
};

export default UploadComponent;
