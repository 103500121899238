import React, { useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import dayjs from "dayjs";
import "dayjs/locale/ka";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import NumberFormat from "../../utils/NumberFormat";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import MRT_Localization_KA from "../../locale/MRT/ka.json";
import { Box, Button, MenuItem } from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { DejaVuSansNormal } from "./dejavu";
import useData from "../../hooks/useData";
// import * as THREE from "three";
//
// import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
// import { MTLLoader, OrbitControls } from "three/addons";

const SalesList = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const { data: list } = useData("/sales/contracts");
  const [selectedTab, setSelectedTab] = useState(1);
  const { t, i18n } = useTranslation();
  const [action, setAction] = useState({
    type: null,
    data: null,
  });
  //
  // const mountRef = useRef(null);
  //
  // useEffect(() => {
  //   // Create the scene
  //   const scene = new THREE.Scene();
  //   scene.background = new THREE.Color(0xffffff); // Set background to white
  //
  //   // Create the camera
  //   const camera = new THREE.PerspectiveCamera(
  //     75,
  //     window.innerWidth / window.innerHeight,
  //     0.1,
  //     10000,
  //   );
  //   camera.position.z = 155;
  //
  //   // Create the renderer
  //   const renderer = new THREE.WebGLRenderer({ antialias: true });
  //   renderer.setSize(window.innerWidth, window.innerHeight);
  //   mountRef.current.appendChild(renderer.domElement);
  //
  //   // Add ambient light
  //   const ambientLight = new THREE.AmbientLight(0x404040, 2); // Soft white light, intensity 2
  //   scene.add(ambientLight);
  //
  //   // Add multiple directional lights
  //   const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1);
  //   directionalLight1.position.set(1, 1, 1).normalize();
  //   scene.add(directionalLight1);
  //
  //   const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1);
  //   directionalLight2.position.set(-1, -1, -1).normalize();
  //   scene.add(directionalLight2);
  //
  //   // Add a point light for better illumination
  //   const pointLight = new THREE.PointLight(0xffffff, 1, 100);
  //   pointLight.position.set(10, 10, 10);
  //   scene.add(pointLight);
  //
  //   // Load the MTL file first to get materials
  //   const mtlLoader = new MTLLoader();
  //   mtlLoader.load("/nino.mtl", (materials) => {
  //     materials.preload();
  //     // Load the OBJ file with materials
  //     const objLoader = new OBJLoader();
  //     objLoader.setMaterials(materials);
  //     objLoader.load(
  //       "/nino.obj", // Path to the OBJ file in the public directory
  //       (object) => {
  //         // object.traverse((child) => {
  //         //   if (child instanceof THREE.Mesh) {
  //         //     child.material = new THREE.MeshLambertMaterial({
  //         //       color: 0xffffff,
  //         //     });
  //         //   }
  //         // });
  //         // Center the object
  //         const box = new THREE.Box3().setFromObject(object);
  //         const center = box.getCenter(new THREE.Vector3());
  //         object.position.sub(center); // Center the object
  //         scene.add(object);
  //       },
  //       (xhr) => {
  //         console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  //       },
  //       (error) => {
  //         console.error("An error happened", error);
  //       },
  //     );
  //   });
  //
  //   const controls = new OrbitControls(camera, renderer.domElement);
  //   controls.enableZoom = true; // Enable zoom
  //   controls.enableDamping = false; // Enable damping (inertia)
  //   controls.dampingFactor = 0.25; // Damping factor
  //   controls.screenSpacePanning = false; // Disable pan in screen space
  //   controls.minDistance = 150; // Minimum zoom distance
  //   controls.maxDistance = 5000; // Maximum zoom distance
  //   controls.update();
  //
  //   // Animation loop
  //   const animate = function () {
  //     requestAnimationFrame(animate);
  //     controls.update(); // Only required if controls.enableDamping = true, or if controls.autoRotate = true
  //     controls.enableZoom = true; // Enable zoom
  //
  //     renderer.render(scene, camera);
  //   };
  //
  //   animate();
  //
  //   // Cleanup on component unmount
  //   return () => {
  //     mountRef.current.removeChild(renderer.domElement);
  //     controls.dispose();
  //   };
  // }, []);

  const { language } = i18n;
  dayjs.locale(language);
  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const getUserData = (row) => {
    return row.original.userData[language]?.name
      ? row.original.userData[language]
      : row.original.userData["ka"];
  };

  const columns = [
    { name: "id", accessorKey: "id", header: t("sales.agreement"), size: 80 },
    {
      name: "name",
      accessorKey: `userData.${language}.name`,
      filterVariant: "autocomplete",
      header: t("sales.client"),
      Cell: ({ row }) => {
        const userData = getUserData(row);
        return <div>{userData.name}</div>;
      },
    },
    {
      name: "phone",
      accessorKey: `userData.${language}.phone`,
      header: t("sales.phone"),
      size: 120,
      Cell: ({ row }) => {
        const userData = getUserData(row);
        return <div>{userData.phone}</div>;
      },
    },
    {
      name: "address",
      accessorKey: `userData.${language}.address`,
      header: t("sales.address"),
      Cell: ({ row }) => {
        const userData = getUserData(row);
        return <div>{userData.address}</div>;
      },
    },
    {
      name: "status",
      size: 120,
      accessorKey: "status",
      filterSelectOptions: [
        {
          label: t("sales.contractStatus.constructive"),
          value: "constructive",
        },
        { label: t("sales.contractStatus.cutting"), value: "cutting" },
        { label: t("sales.contractStatus.assembly"), value: "assembly" },
        { label: t("sales.contractStatus.delivery"), value: "delivery" },
        {
          label: t("sales.contractStatus.installation"),
          value: "installation",
        },
        { label: t("sales.contractStatus.completed"), value: "completed" },
      ],
      filterVariant: "multi-select",
      header: t("sales.status"),
      Cell: ({ cell }) => (
        <span className={cn("badge", `badge-${cell.getValue()}`)}>
          {t(`sales.contractStatus.${cell.getValue()}`)}
        </span>
      ),
    },
    {
      name: "created",
      accessorKey: "created",
      header: t("sales.date"),
      size: 100,
      Cell: ({ cell }) => (
        <div>{dayjs(new Date(cell.getValue())).format("D MMM")}</div>
      ),
    },
    {
      size: 100,
      name: "dueDate",
      accessorKey: "days",
      header: t("sales.dueDate"),
      Cell: ({ row }) => (
        <div>
          {dayjs(new Date(row.original.created))
            .add(row.original.days || 15, "d")
            .format("D MMM")}
        </div>
      ),
    },
    {
      size: 100,
      name: "price",
      accessorKey: "price",
      header: t("sales.price"),
      Cell: ({ cell }) => <div>{NumberFormat(cell.getValue())} ₾</div>,
    },
    {
      name: "left",
      accessorKey: "total",
      header: t("sales.left"),
      Cell: ({ row }) => {
        const paid = row.original.price - row.original.total < 1;
        return (
          <div>
            <span
              className={cn(
                "badge",
                paid ? "badge-linesuccess" : "badge-linedanger",
              )}
            >
              {paid
                ? t(`sales.paid`)
                : `${NumberFormat(row.original.price - row.original.total)} ₾`}
            </span>
          </div>
        );
      },
    },
  ];

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => {
      const userData = getUserData(row);
      const paid = row.original.price - row.original.total < 1;

      return [
        row.original.id,
        userData.name,
        userData.phone,
        userData.address,
        t("sales.contractStatus." + row.original.status),
        dayjs(new Date(row.original.created)).format("D MMM"),
        dayjs(new Date(row.original.created))
          .add(row.original.days || 15, "d")
          .format("D MMM"),
        `${NumberFormat(row.original.price)} ₾GEL`,
        paid
          ? t(`sales.paid`)
          : `${NumberFormat(row.original.price - row.original.total)} ₾GEL`,
      ];
    });
    const tableHeaders = columns.map((column) => column.header);

    doc.addFileToVFS("DejaVuSans-normal.ttf", DejaVuSansNormal);
    doc.addFont("DejaVuSans-normal.ttf", "DejaVuSans", "normal");

    // Set the font to use
    doc.setFont("DejaVuSans");

    autoTable(doc, {
      didDrawCell: (data) => {
        // Optional: Apply font settings per cell if needed
        if (data.column.index === 0 && data.cell.section === "body") {
          doc.setFont("DejaVuSans"); // Set custom font for specific cells
        }
      },
      tableWidth: "auto",
      margin: { top: 3, right: 3, bottom: 3, left: 3 },
      headStyles: {
        fillColor: [254, 198, 57], // Background color for headers
        textColor: [0, 0, 0], // Optional: set text color if needed
        halign: "center", // Align text in the center of the cell
      },
      styles: { font: "DejaVuSans", fontSize: 8 }, // Set the font for all cells in the table
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("contract-list.pdf");
  };

  const items = useMemo(
    () => [
      {
        key: "1",
        label: (
          <>
            <ImageWithBasePath
              src="assets/img/flags/ge.png"
              alt="img"
              height={10}
              width={14}
            />{" "}
            ქართული
          </>
        ),
        children: (
          <iframe
            name="contract-0"
            id="contract-0"
            src={`https://www.presta.ge/contract?id=${action.data?.id}&fromAWS=1.2.3.4`}
            frameBorder={0}
            width="100%"
            height={600}
          />
        ),
      },
      {
        disabled: !action.data?.userData.ru,
        key: "2",
        label: (
          <>
            <ImageWithBasePath
              src="assets/img/flags/ru.png"
              alt="img"
              height={10}
              width={14}
            />{" "}
            Русский
          </>
        ),
        children: (
          <iframe
            name="contract-1"
            id="contract-1"
            src={`https://www.presta.ge/ru/contract?id=${action.data?.id}&fromAWS=1.2.3.4`}
            frameBorder={0}
            width="100%"
            height={600}
          />
        ),
      },
      {
        key: "3",
        disabled: !action.data?.userData.en,
        label: (
          <>
            <ImageWithBasePath
              src="assets/img/flags/us.png"
              alt="img"
              height={10}
              width={14}
            />{" "}
            English
          </>
        ),
        children: (
          <iframe
            name={"contract-2"}
            id="contract-2"
            src={`https://www.presta.ge/en/contract?id=${action.data?.id}&fromAWS=1.2.3.4`}
            frameBorder={0}
            width="100%"
            height={600}
          />
        ),
      },
    ],
    [action],
  );

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Sales List</h4>
                <h6>Manage Your Sales</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-sales-new"
              >
                <PlusCircle className="me-2" />
                Add New Sales
              </Link>
            </div>
          </div>
          {/*<div ref={mountRef}></div>*/}
          {/* /product list */}
          {list && (
            <div className="table-responsive">
              <MaterialReactTable
                data={list}
                columns={columns}
                sortDescFirst
                enableRowActions={true}
                positionActionsColumn="last"
                localization={
                  language === "ka"
                    ? MRT_Localization_KA
                    : language === "en"
                      ? MRT_Localization_EN
                      : MRT_Localization_RU
                }
                initialState={{
                  pagination: {
                    pageSize: 20,
                    pageIndex: 0,
                  },
                  showGlobalFilter: true,
                  paginationDisplayMode: "pages",
                }}
                renderTopToolbarCustomActions={({ table }) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "16px",
                        padding: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      <OverlayTrigger placement="top" overlay={renderTooltip}>
                        <Button
                          disabled={
                            table.getPrePaginationRowModel().rows.length === 0
                          }
                          //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleExportRows(
                              table.getPrePaginationRowModel().rows,
                            )
                          }
                          startIcon={
                            <ImageWithBasePath
                              src="assets/img/icons/pdf.svg"
                              alt="img"
                            />
                          }
                        />
                      </OverlayTrigger>
                    </Box>
                  );
                }}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                  <MenuItem
                    key={1}
                    onClick={() => {
                      console.info("View Profile", row);
                      setAction({ type: "view", data: row.original });
                      closeMenu();
                    }}
                  >
                    View Details
                  </MenuItem>,
                  <MenuItem
                    key={2}
                    onClick={() => {
                      console.info("Remove", row);
                      closeMenu();
                    }}
                  >
                    Edit
                  </MenuItem>,
                  <MenuItem
                    key={3}
                    onClick={() => {
                      console.info("Share", row);
                      closeMenu();
                    }}
                  >
                    Remove
                  </MenuItem>,
                ]}
              />
            </div>
          )}
          {/* /product list */}
        </div>
      </div>
      <Modal
        title={action.data?.id}
        open={action.type === "view"}
        onCancel={() => {
          setAction({ type: null, data: null });
        }}
        okText={"Print"}
        onOk={() => {
          const path =
            selectedTab === "2" ? "ru/" : selectedTab === "3" ? "en/" : "";
          window.open(
            `https://presta.ge/${path}contract?id=${action.data?.id}&fromAWS=1.2.3.4&print=true`,
            "targetWindow",
            `toolbar=no,
                          location=no,
                          status=no,
                          menubar=no,
                          scrollbars=yes,
                          resizable=yes,
                          width=SomeSize,
                          height=SomeSize`,
          );
        }}
        width="80%"
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          centered
          key={action.data?.id}
          onChange={setSelectedTab}
        />
      </Modal>
    </div>
  );
};

export default SalesList;
